// @codekit-prepend quiet "../vendor/flickity.pkgd.min.js", "../vendor/magnific.min.js";
var xpo = {

	init: function() {
		
		xpo.pageScroll();
		xpo.initSlider();
		xpo.initLightbox();
		xpo.contentOnLoad();
		xpo.accordion();
		xpo.noWidows($('p, a, li').not('.nav__item, .video-link__link'));
		
	},
	
	// Read a page's GET URL variables and return them as an associative array.
	getParameterByName: function(name) {
		var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	},
	
	// Event listener to scroll page to section
	pageScroll: function() {
		
		$('.js-scroll').on('click', function(e) {
			e.preventDefault();
			var currentLink = this.hash;
			if(currentLink.length) {
				xpo.scrollIt(currentLink,500);
			}
		});	
	},

	contentOnLoad: function() {

		// If path contains a hash on load/hashchange jump straight to anchor
		$(window).on('load hashchange', function () {
			if (location.hash) {
				var currentLink = location.hash;
				xpo.scrollIt(currentLink, 10);
			};
		});
	},

	// Nav scroll-to-section
	scrollIt: function(loc,speed) {
		var offsettery = $(loc).offset().top;
		$('html, body').stop().animate({scrollTop: offsettery}, speed);
		if(history.pushState) {
			history.pushState(null, null, loc);
		}
		else {
			location.hash = loc;
		}
	},
	
	// Remove widows from content
	initSlider: function(e) {
		var sliders = [];
		
		// Sliders
    $('.js-slider').each(function(i) {
      sliders[i] = $(this).flickity({
        adaptiveHeight: true,
				imagesLoaded: true,
				selectedAttraction: 0.1,
				friction: 0.5,
				pageDots: false,
				arrowShape: 'M13.88,46.43c-1.94,1.34-2.43,4-1.1,5.94,.3,.43,.67,.8,1.1,1.1l55.66,38.54c1.94,1.35,4.6,.87,5.95-1.07,.5-.72,.77-1.58,.76-2.46V11.42c-.01-2.37-1.94-4.28-4.31-4.27-.86,0-1.7,.27-2.41,.75L13.88,46.43Z'
			});
			if ($(this).find('.js-slide').length === 1) {
				$(this).find('button.previous, button.next').hide();
			}
    });
	},

	// Init Lightbox
	initLightbox: function () {
		
		$('.js-lightbox-inline').magnificPopup({
			alignTop: true,
			midClick: true,
			closeOnBgClick: false,
		});

		$('.js-lightbox-iframe').magnificPopup({
			type:'iframe',
		});
		
	},

	// Accordion
	accordion: function () {
		
		$('.js-accordion-toggle').on('click', function () {
			$(this).toggleClass('is-active');
			//$(this).find('.js-accordion-content').slideToggle('fast');
		});
		
	},

	// Remove widows from content
	noWidows: function(e) {
		e.each(function(){
			$(this).html($(this).html().replace(/\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/,'&nbsp;$1'));
		});
	}

}

// Document ready actions

$(function(){
	
	// Init
	xpo.init();
		
});